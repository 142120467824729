import { OnInit, ViewChild, Injector, OnDestroy, Directive } from '@angular/core';
import { timer, Subscription, filter } from 'rxjs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { ImportSchema } from '../../import/import-schema';
import { ItemList } from '../../interfaces/item-list';

import { ClrDatagrid } from '@clr/angular';

import { ErrorService } from '../../services/error.service';
import { TitleService } from '../../services/title.service';
import { SpinnerService } from '../../services/spinner.service';
import { DateTimeService } from '../../services/datetime.service';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProductPropertyTypes, DecimalTypes, DateTypes } from '../../../auction/shared/models/product';

// import '../styles/item-list.component.scss';

const lineBreak = '\\r\\n';
const lineBreakHtml = '<br />';

@Directive()
export class ItemListComponent<T> implements ItemList<T>, OnInit, OnDestroy {

  // ItemList<T> implementation
  searchText: string;
  items: Array<T>;
  pageSize: number;
  pageSizeValue = 50;
  isPrinting: boolean;
  exportedData: string;
  translations: any; // make readonly
  schema: ImportSchema;
  id: number;
  routeSubscription: Subscription;
  // catalog helper property
  isForecastCatalog: boolean;
  defaultLanguge: string;

  // Deprecated (Clarity grid is no longer used)
  @ViewChild(ClrDatagrid) datagrid: ClrDatagrid;

  protected errorService: ErrorService;
  protected title: TitleService;
  protected spinner: SpinnerService;
  protected datetime: DateTimeService;
  protected language: LanguageService;
  protected translate: TranslateService;
  protected router: Router;
  protected route: ActivatedRoute;

  public model: T;

  constructor(protected injector: Injector, creator: { new(): T }) {
    this.errorService = injector.get(ErrorService);
    this.title = injector.get(TitleService);
    this.spinner = injector.get(SpinnerService);
    this.datetime = injector.get(DateTimeService);
    this.translate = injector.get(TranslateService);
    this.language = injector.get(LanguageService);
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);

    this.pageSize = this.pageSizeValue;
    this.isPrinting = false;
    this.model = new creator();

    this.route.data.subscribe(data => {
      this.isForecastCatalog = data['isForecast'] ? true : false;
    });

    // handing route activation when it is not triggered by navigation itself
    this.routeSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // detect id change
        this.id = +this.route.snapshot.params['id'];
        // if id exists it the route, refresh data
        if (this.id) {
          this.getData();
        }
      });
  }

  ngOnInit(): void { };

  getData(): void { };

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  // Data extraction:
  doWithoutPaging(action: { (data: any, schema: any): any }) { // tslint:disable-line:callable-types
    // this method executes desired action in a promise-based manner, taking all the data into account
    let schema: any; // tslint:disable-line:prefer-const
    return new Promise(resolve => { // tslint:disable-line:no-inferred-empty-object-type
      this.isPrinting = true;
      this.pageSize = Number.MAX_VALUE;
      let datagrid = document.getElementsByClassName('datagrid') as HTMLCollectionOf<HTMLElement>;
      if (datagrid.length > 0) {
        datagrid[0].style.maxHeight = "100%";
      }

      // if the action is called without a timer, pager is not yet removed
      timer(1).subscribe(
        (data) => {
          resolve(action(data, schema));
          // switch back the paging after the method execution
          this.pageSize = this.items.length < this.pageSizeValue ? this.items.length : this.pageSizeValue;
          let datagrid = document.getElementsByClassName('datagrid') as HTMLCollectionOf<HTMLElement>;
          if (datagrid.length > 0) {
            datagrid[0].style.maxHeight = "calc(100vh - 240px)";
          }
          window.setTimeout(() => this.datagrid.resize(), 0);
          this.isPrinting = false;
        }
      );
    });
  }

  print(): void {
    this.doWithoutPaging(() => { window.print(); }); // tslint:disable-line:no-floating-promises
  }

  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function (options) {
        const excelCell = options.excelCell;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      }
    }).then(function () {
      workbook.xlsx.writeBuffer()
        .then(function (buffer: BlobPart) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
        });
    });
    e.cancel = true;
  }

  setPageSize(event: any) {
    this.pageSizeValue = event;
    this.pageSize = event;
  }

  setTranslations(key: string) {
    this.translate.get(key).subscribe((res: string) => {
      this.translations = res;
      this.createDataSchema();
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get(key).subscribe((res: string) => {
        this.translations = res;
        this.createDataSchema();
      });
    });
  }

    getDataType(fieldType: number, format: number): 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' {
    switch (fieldType) {
      case ProductPropertyTypes.NUMBER: {
        return 'number';
      }
      case ProductPropertyTypes.DECIMAL: {
        return 'number';
      }
      case ProductPropertyTypes.TEXT: {
        return 'string';
      }
      case ProductPropertyTypes.DATE: {
        if(format == DateTypes.FULL_DATE_TIME) return 'datetime';
        return 'date';
      }
      case ProductPropertyTypes.BOOLEAN: {
        return 'boolean';
      }
      case ProductPropertyTypes.COLOR: {
        return 'string';
      }
      case ProductPropertyTypes.URL: {
        return 'string';
      }
      default: break;
    }
    return
  }

  createDataSchema(): void { };

  // Replace '\r\n' with '<br />'
  protected addLineBreaks(value: string, propertyTypeId: ProductPropertyTypes = null, propertyTypeFormat: number = null): string {
    let values = (value || '').toString().split(lineBreak);

    let valuesParsed = Array<string>();
    values.forEach(v => {
      valuesParsed.push(this.format(v, propertyTypeId, propertyTypeFormat));
    });

    value = valuesParsed.join(lineBreakHtml);
    return value;
  }

  // Replaces HTML line break ('<br />') or new line ('\r\n') with separator (dash by default: '-' )
  protected removeLineBreaks(value: string, separator: string = '-'): string {
    value = this.addLineBreaks((value || '').toString());
    let values = value.split(lineBreakHtml);
    value = values.join(` ${separator} `);
    return value;
  }

  protected format(value: any, propertyTypeId: ProductPropertyTypes = null, propertyTypeFormatId: number = null, decimalPlaces: number = null, useGrouping: boolean = true) {
    if (!(value === undefined || value === null) && propertyTypeId == ProductPropertyTypes.DATE) {
      return this.datetime.getDateStringByFormatAnyUtc(value, propertyTypeFormatId);
    }

    if (value === undefined
      || value === null
      || isNaN(value)
      || isNaN(parseFloat(value))
      || propertyTypeId == null
      || propertyTypeId == ProductPropertyTypes.BOOLEAN
      || propertyTypeId == ProductPropertyTypes.IMAGE
      || propertyTypeId == ProductPropertyTypes.MASTER_DATA_VALUE
      || propertyTypeId == ProductPropertyTypes.TEXT
      || propertyTypeId == ProductPropertyTypes.COLOR
      || propertyTypeId == ProductPropertyTypes.URL) {
      return value;
    }

    if (propertyTypeFormatId != null && decimalPlaces == null) {
      switch (propertyTypeFormatId) {
        case DecimalTypes.DECIMAL_0digit:
          decimalPlaces = 0;
          break;
        case DecimalTypes.DECIMAL_1digit:
          decimalPlaces = 1;
          break;
        case DecimalTypes.DECIMAL_2digit:
          decimalPlaces = 2;
          break;
        case DecimalTypes.DECIMAL_3digit:
          decimalPlaces = 3;
          break;
        case DecimalTypes.DECIMAL_4digit:
          decimalPlaces = 4;
          break;
        case DecimalTypes.DECIMAL_5digit:
          decimalPlaces = 5;
          break;
      }
    }

    let number = parseFloat(value);
    let minDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 0 : decimalPlaces;
    let maxDecimalPlaces = (decimalPlaces === undefined || decimalPlaces === null) ? 18 : decimalPlaces;

    let culture = this.translate.currentLang;

    var numberFormatter = new Intl.NumberFormat(culture, { useGrouping: useGrouping, minimumFractionDigits: minDecimalPlaces, maximumFractionDigits: maxDecimalPlaces });

    return numberFormatter.format(number);
  }

  protected getpropertyTypeFormatId(product, productPropertyId) {
    const prop = product.productProperties.find(p => p.productPropertyId === productPropertyId);
    return prop && prop.propertyTypeFormatId ? prop.propertyTypeFormatId : -1;
  }

  protected getTranslation(value: string) {
    if (!value) {
      return '';
    }
    if (!this.isJSONString(value)) {
      return value && value.replace ? value.replace(/"/g, "'").replace(/\\/g, "\\\\") : value;
    }

    this.language.getLanguages().subscribe(languages => {
      this.defaultLanguge = languages.find(_ => _.isDefault).code;
    });
    const translated = this.language.getTranslatableText(value, this.defaultLanguge);
    return translated;
  }

  protected isJSONString(str: string) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {
      //
    }
    return false;
  }
}
