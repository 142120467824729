export class RegisterInfo {

  applicationCode: string;
  email: string;
  password: string;
  confirmPassword: string;

  constructor(applicationCode?: string) {
    this.applicationCode = applicationCode;
  }
}
