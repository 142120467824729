<div class="buyer-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addBuyer
            [width]="600"
            [showTitle]="true"
            title="{{ modalTitle }}"
            [dragEnabled]="false"
            [closeOnOutsideClick]="false"
            [rtlEnabled]="rtlEnabled"
            [(visible)]="isOpened"
            (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #buyerForm
                 id="buyerForm"
                 validationGroup="buyerData"
                 [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)"
                 colCount="auto"
                 [(formData)]="model"
                 [minColWidth]="500">
          <dxi-item itemType="group">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="address1">
              <dxo-label text="{{ 'SHARED.ADDRESS1' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.ADDRESS1' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="address2">
              <dxo-label text="{{ 'SHARED.ADDRESS2' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="zip">
              <dxo-label text="{{ 'SHARED.ZIP' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.ZIP' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="city">
              <dxo-label text="{{ 'SHARED.CITY' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.CITY' | translate }}  {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="country">
              <dxo-label text="{{ 'SHARED.COUNTRY' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.COUNTRY' | translate }}  {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="vat">
              <dxo-label text="{{ 'SHARED.VAT' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.VAT' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="telNumber">
              <dxo-label text="{{ 'SHARED.TEL_NUMBER' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.TEL_NUMBER' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="faxNumber">
              <dxo-label text="{{ 'SHARED.FAX_NUMBER' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="email">
              <dxo-label text="{{ 'SHARED.EMAIL' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.EMAIL' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="url">
              <dxo-label text="{{ 'SHARED.URL' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="isActive" editorType="dxCheckBox">
              <dxo-label text="{{ 'SHARED.IS_ACTIVE' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item dataField="userLicenceCount"
                      editorType="dxNumberBox"
                      [editorOptions]="{
                showSpinButtons: true,
                mode: 'number',
                format: '#0',
                min: 0,
                step: 1}">
              <dxo-label text="{{ 'BUYER.USER_LICENCES' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'BUYER.USER_LICENCES' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}"></dxi-validation-rule>
            </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" [(visible)]="!isEditMode">
            <dxi-item dataField="assignAdminUser" [template]="'assignAdminUserTemplate'">
              <dxo-label text="{{ 'AUCTIONS_CLUSTERS.ADMIN_USER_LABEL' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="administratorId"
                      editorType="dxSelectBox"
                      [editorOptions]="{ items: users, valueExpr:'userId' ,
              displayExpr: returnValues, searchEnabled: 'true', showClearButton: 'true', disabled: !assignAdminUser}">
              <dxo-label [visible]="false"></dxo-label>
            </dxi-item>
          </dxi-item>

          <div *dxTemplate="let data of 'assignAdminUserTemplate'">
            <dx-check-box [(value)]="assignAdminUser"></dx-check-box>
          </div>

        </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnBuyerSubmit" disabled="disabled">{{ 'SHARED.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>
      </div>
    </dx-scroll-view>
  </dx-popup>
</div>
<confirmation-component #licenseConfirmation buttonCloseText="{{ 'SHARED.OK' | translate }}" title="{{ 'ERRORS.ALL_BUYER_LICENCES_USED' | translate }}"></confirmation-component>
