import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AdditionalMenuItem } from '../models/additional-menu-item';
import { WebApiService } from './web-api.service';
import { ApplicationSettings } from '../models/application-settings';

@Injectable()
export class AdditionalMenuItemsService {

  private apiPath: string;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi + 'menu-items/public';
  }

  getPublicMenuItems(): Observable<Array<AdditionalMenuItem>> {
    return this.webApiService.getList(this.apiPath);
  }

  getPublicMenuItem(externalAppId: number): Observable<AdditionalMenuItem> {
    return this.webApiService.get(this.apiPath, externalAppId);
  }
}
