export class Buyer {
  buyerId: number;
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  country: string;
  vat: string;
  telNumber: string;
  faxNumber: string;
  email: string;
  url: string;
  isActive: boolean;
  administratorId: number;
  isPending: boolean;
  userLicenceCount: number;
}

export class BuyerSimple {
  buyerId: number;
  name: string;
  vat: string;
}

export function BuyerCompareName(buyer1, buyer2) {
  if (buyer1.name < buyer2.name) {
    return -1;
  }
  else if (buyer1.name > buyer2.name) {
    return 1;
  }
  return 0;
}

export function BuyerCompareNumber(buyer1, buyer2) {
  if (buyer1.buyerNumber < buyer2.buyerNumber) {
    return -1;
  }
  else if (buyer1.buyerNumber > buyer2.buyerNumber) {
    return 1;
  }
  return 0;
}