<div class="card register-assign-account-component">
  <div class="card-header">
    {{ 'REGISTER.ASSIGN_ACCOUNT' | translate }}
  </div>
  <form #registerAssignAccountForm="ngForm" (ngSubmit)="registerAssignAccount()" style="padding-top: 0.5rem">
  <div class="card-block">
      <div class="card-block">
        <section class="form-block">
          <div class="form-group" id="outter-div">
            <label class="required" id="label">{{ 'REGISTER.DESIRED_ACCESS' | translate }}:</label>
            <label for="desiredAccess" aria-haspopup="true" role="tooltip" class="tooltip tooltip-bottom-left tooltip-validation tooltip-sm">
              <div class="select">
                <select name="desiredAccess" [(ngModel)]="model.desiredAccess" class="inner-select">
                  <option [ngValue]="0">{{ 'SHARED.BUYER' | translate }}</option>
                  <option [ngValue]="1">{{ 'SHARED.SUPPLIER' | translate }}</option>
                </select>
              </div>
            </label>
          </div>
          <div class="form-group" id="outter-div" *ngIf="auctionClusters.length > 1">
            <label class="required" id="label" for="auctionCluster">{{ 'SHARED.AUCTION_CLUSTER' | translate }}</label>
            <div class="select">
              <select name="auctionCluster" [(ngModel)]="user.existingAuctionClusterId" class="inner-select">
                <option *ngFor="let auctionCluster of auctionClusters" [ngValue]="auctionCluster.auctionClusterId">{{ auctionCluster.name }}</option>
              </select>
            </div>
          </div>

            <div class="radio-group" *ngIf="model.desiredAccess === DesiredAccess.BUYER">
              <!--<div class="radio radio-group-items">
                <input type="radio" name="menuOption" id="existingBuyerAccount" [checked]="model.buyerAccountType === AccountType.EXISTING" (change)="changeBuyerAccountType(AccountType.EXISTING)" />
                <label for="existingBuyerAccount">{{ 'REGISTER.EXISTING_BUYER_ACCOUNT' | translate }}</label>
              </div>-->
              <div class="radio-group-subitems" *ngIf="model.buyerAccountType === AccountType.EXISTING">
                <section class="form-block">
                  <div class="form-group" id="outter-div">
                    <label class="required" id="label" for="existingBuyerVatCountryCode">{{ 'SHARED.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="existingBuyerVatCountryCode" class="inner-select" style="padding-bottom: 3px;" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="user.existingBuyerVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="existingBuyerVatNumber" class="input vat tooltip tooltip-validation tooltip-md" [class.invalid]="existingBuyerVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" class="inner-select" id="existingBuyerVatNumber" #existingBuyerVatNumberLocal="ngModel" name="existingBuyerVatNumber" [(ngModel)]="vatNumber" (change)="user.existingBuyerVat = vatCountryCode + vatNumber" [placeholder]="('SHARED.EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                </section>
              </div>
            </div>

            <div class="radio-group" *ngIf="model.desiredAccess === DesiredAccess.SUPPLIER">
              <div class="radio-group-subitems" *ngIf="model.supplierAccountType === AccountType.EXISTING">
                <section class="form-block">
                  <div class="form-group" id="outter-div">
                    <label class="required" id="label" for="existingSupplierVatCountryCode">{{ 'SHARED.VAT' | translate }}:</label>
                    <div class="select" style="margin-right: 0">
                      <select id="existingSupplierVatCountryCode" class="inner-select" style="padding-bottom: 3px;" name="vatCountryCode" [(ngModel)]="vatCountryCode" (change)="user.existingSupplierVat = vatCountryCode + vatNumber">
                        <option *ngFor="let countryCode of vatService.getCountryCodes()" [value]="countryCode" [attr.selected]="countryCode === vatCountryCode">
                          {{ countryCode }}
                        </option>
                      </select>
                    </div>
                    <label for="existingSupplierVatNumber" class="input vat tooltip tooltip-validation tooltip-md" [class.invalid]="existingSupplierVatNumberLocal.dirty && !vatService.validate(vatCountryCode, vatNumber)" role="tooltip" aria-haspopup="true">
                      <input type="text" class="inner-select" id="existingSupplierVatNumber" name="existingSupplierVatNumber" #existingSupplierVatNumberLocal="ngModel" [(ngModel)]="vatNumber" (change)="user.existingSupplierVat = vatCountryCode + vatNumber" [placeholder]="('SHARED.EXAMPLE' | translate) + ' ' + vatService.getExample(vatCountryCode)">
                      <span class="tooltip-content">{{ 'SUPPLIERS.VAT_INVALID' | translate }}</span>
                    </label>
                  </div>
                </section>
              </div>
            </div>
        </section>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" class="btn btn-primary" [disabled]="registerAssignAccountForm.form.invalid || !vatService.validate(vatCountryCode, vatNumber)">{{ 'SHARED.FINISH' | translate }}</button>
    </div>
  </form>
</div>
