import { Component, Injector, ViewChild, OnInit, ViewEncapsulation, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { ClrTabs } from '@clr/angular';
import { forkJoin, Subscription, firstValueFrom } from 'rxjs';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { ConfigService } from '../../shared/services/config.service';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// services
import { UserService } from '../shared/services/user.service';
import { BuyerRoleService } from '../../shared/services/buyer-role.service';
import { SupplierRoleService } from '../../shared/services/supplier-role.service';

// models
import { User, UserBuyerRole, UserAuctionClusterRole, UserSupplierRole, Longtab } from '../shared/models/user';
import { PlatformRole } from '../shared/models/platform-role';
import { Buyer } from '../../shared/models/buyer';
import { Supplier } from '../../shared/models/supplier';
import { Auction } from '../../auction/shared/models/auction';
import { SupplierRole } from '../../shared/models/supplier-role';
import { BuyerRole } from '../../shared/models/buyer-role';
import { AuctionClusterRole } from '../../shared/models/auction-cluster-role';
import { UserAuctionClusterItem } from '../../shared/models/user-auction-cluster-item';
import { AuctionCluster } from '../shared/models/auction-cluster';
import { Language } from '../../shared/models/language';
import { LanguagePreferenceService } from '../../shared/services/language-preference.service';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';

import { DxPopupComponent } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { BuyerService } from '../../shared/services/buyer.service';

class UserBuyerItem extends UserBuyerRole {
  filteredBuyerRoles: Array<BuyerRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

class UserSupplierItem extends UserSupplierRole {
  filteredSupplierRoles: Array<SupplierRole> = [];
  auctionFilterData = '-';
  catalogFilterData = '-';
  clockFilterData = '-';
}

const ESC_KEYCODE = 27;

@Component({
  selector: 'user-component',
  templateUrl: 'user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UserComponent extends ItemDetailsComponent<User> implements OnInit, OnDestroy {

  // tabs checkers
  isPlatformUser = false;
  isAuctionClusterUser = false;
  isBuyerUser = false;
  isSupplierUser = false;
  isSystemUser = false;

  platformRoles: Array<PlatformRole> = [];

  auctionClusters: Array<AuctionCluster> = [];
  auctions: Array<Auction>;
  auctionClusterRoles: Array<AuctionClusterRole> = [];

  buyers: Array<Buyer>;
  buyerRoles: Array<BuyerRole> = [];

  suppliers: Array<Supplier>;
  supplierRoles: Array<SupplierRole> = [];

  auctionClusterTable = null;
  supplierTable = null;
  buyerTable = null;

  tabItems: Array<Longtab> = [{ text: 'test' }, { text: 'test2' }, { text: 'test.ts' }];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  platformRoleSetup = {};

  languages: Array<Language> = [];

  formDirty: boolean = false;

  buyersPaginated: any = {};
  suppliersPaginated: any = {};

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('email', { read: ElementRef }) emailInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;
  @ViewChild('licenseConfirmation') licenseConfirmation: ConfirmationComponent;
  

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: UserService,
    private buyerService: BuyerService,
    private languagePreferenceService: LanguagePreferenceService,
    public configService: ConfigService,
    private supplierRoleService: SupplierRoleService,
    private buyerRoleService: BuyerRoleService,
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  async ngOnInit() {
    this.model = new User();
    this.languages = await this.languageService.getLanguages().toPromise();
    this.clean();
    this.setTranslations('USER');
  }

  translateSelectBoxes = (item) => {
    if (item) {
      const label = this.getTranslation(item.name);
      return label;
    }
  }

  open(
    users: Array<User>,
    userId: number,
    platformRoles: Array<PlatformRole>,
    auctionClusters: Array<AuctionCluster>,
    auctions: Array<Auction>,
    auctionClusterRoles: Array<AuctionClusterRole>,
    buyers: Array<Buyer>,
    suppliers: Array<Supplier>,
    isSystemUser: boolean
  ) {
    this.allItems = users;

    this.platformRoles = platformRoles;

    this.auctionClusters = auctionClusters;
    // this.auctions = auctions;
    this.isSystemUser = isSystemUser;

    const allSelectioAuctions  = new Auction();
    allSelectioAuctions.auctionId = null;
    allSelectioAuctions.auctionClusterId = null;
    allSelectioAuctions.name = this.translations.ALL_AUCTIONS;

    this.auctions = [allSelectioAuctions, ...auctions];

    this.auctionClusterRoles = auctionClusterRoles;

    this.buyers = buyers;

    this.buyersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.buyers,
      sort: 'name'
    };

    this.suppliers = suppliers;

    this.suppliersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.suppliers,
      sort: 'name'
    };

    // Autoselect first tab
    //this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;
    this.clean();

    if (userId != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getUser(userId)
        .subscribe((res: User) => {
          this.model = res;
          if(this.model.buyerRoles[0])
            this.buyerRoles = this.model.buyerRoles[0].availableBuyerRoles;
          if(this.model.supplierRoles[0])
            this.supplierRoles = this.model.supplierRoles[0].availableSupplierRoles;
          this.parseChildObjects();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(this.errorService.translations.TWO_IDENTICAL_ROWS);
            this.spinner.hide();
          });
    } else {
      this.model = new User();
      this.model.isActive = true;
      this.model.sendAccountInfo = false;
      this.model.languageId = this.languages.find(_ => _.code.toLowerCase().indexOf('en') !== -1).languageId;
      this.isPlatformUser = true;
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.emailInput.nativeElement.focus(); });

  }

  getMatchingSupplier(): Supplier | undefined {
    if (!this.shouldShowSupplierApproval()) {
      return undefined;
    }

    return this.suppliers.find(supplier => supplier.vat === this.model.existingSupplierVat);
  }

  shouldShowSupplierApproval() {
    return this.model && this.model.existingSupplierVat !== '' && this.model.isPending;
  }

  shouldShowBuyerApproval() {
    return this.model && this.model.existingBuyerVat !== '' && this.model.isPending;
  }

  addToSupplierAccount(supplier: Supplier) {
    const alreadyAdded = this.model.supplierRoles.find(sr => sr.supplierId === supplier.supplierId);
    if (alreadyAdded) {
      return;
    }

    this.supplierRoleService.getSupplierRolesForSupplierPlatform(supplier.supplierId)
    .subscribe(result => {
      let roles = result;
      this.isSupplierUser = true;
      const supplierRole = new UserSupplierItem();
      supplierRole.supplierId = supplier.supplierId;
      this.supplierRoles = roles;
      this.filterSupplierItem(supplierRole, roles);
      supplierRole.supplierRoleId = supplierRole.filteredSupplierRoles.find(_ => true).supplierRoleId;
      this.model.supplierRoles.push(supplierRole);
    }, error => {
      this.errorService.show(error);
    });
  }

  getMatchingBuyer(): Buyer | undefined {
    if (!this.shouldShowBuyerApproval()) {
      return undefined;
    }

    return (this.buyers || []).find(buyer => buyer.vat === this.model.existingBuyerVat);
  }

  addToBuyerAccount(buyer: Buyer) {
    const alreadyAdded = this.model.buyerRoles.find(buyerRole => buyerRole.buyerId === buyer.buyerId);
    if (alreadyAdded) {
      return;
    }

    this.buyerRoleService.getBuyerRolesForBuyerPlatform(buyer.buyerId).subscribe(result => {
      let roles = result;
      this.isBuyerUser = true;
      const userBuyer = new UserBuyerItem();
      userBuyer.buyerId = buyer.buyerId;
      this.buyerRoles = roles;
      this.filterBuyerItem(userBuyer, roles);
      userBuyer.buyerRoleId = userBuyer.filteredBuyerRoles.find(_ => true).buyerRoleId;
    this.model.buyerRoles.push(userBuyer);
    }, error => {
      this.errorService.show(error);
    });
  }

  private clean() {
    /*if (this.auctionClusterTable !== null) {
      this.auctionClusterTable.instance().refresh();
    }
    if (this.buyerTable !== null) {
      this.buyerTable.instance().refresh();
    }
    if (this.supplierTable !== null) {
      this.supplierTable.instance().refresh();
    }*/
    this.isPlatformUser = false;
    this.isAuctionClusterUser = false;
    this.isBuyerUser = false;
    this.isSupplierUser = false;
  }

  // Parse 'auctionClusterRoles', 'buyerRoles' and 'supplierRoles' fields
  private parseChildObjects() {

    if (this.model.platformRoleId) {
      this.isPlatformUser = true;
    }

    if (!this.model.auctionClusterRoles) {
      this.model.auctionClusterRoles = [];
    } else {
      this.model.auctionClusterRoles = this.model.auctionClusterRoles.map(source => {
        const item = new UserAuctionClusterItem();
        item.auctionClusterId = source.auctionClusterId;
        item.auctionId = source.auctionId;
        item.auctionClusterRoleId = source.auctionClusterRoleId;
        this.filterAuctionClusterItem(item);
        return item;
      });
    }

    this.isAuctionClusterUser = this.model.auctionClusterRoles.length !== 0;

    if (!this.model.buyerRoles) {
      this.model.buyerRoles = [];
    } else {
      this.model.buyerRoles = this.model.buyerRoles.map(source => {
        const item = new UserBuyerItem();
        item.buyerId = source.buyerId;
        item.buyerRoleId = source.buyerRoleId;

        this.filterBuyerItem(item, source.availableBuyerRoles);
        return item;
      });
    }

    this.isBuyerUser = this.model.buyerRoles.length !== 0;

    if (!this.model.supplierRoles) {
      this.model.supplierRoles = [];
    } else {
      this.model.supplierRoles = this.model.supplierRoles.map(source => {
        const item = new UserSupplierItem();
        item.supplierId = source.supplierId;
        item.supplierRoleId = source.supplierRoleId;
        this.filterSupplierItem(item, source.availableSupplierRoles);
        return item;
      });
    }

    this.isSupplierUser = this.model.supplierRoles.length !== 0;
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  onSubmitAccountInfo() {
    this.model.sendAccountInfo = true;
    this.save();
  }

  save() {

    if (this.auctionClusterTable !== null) {
      this.auctionClusterTable.instance().closeEditCell();
      this.auctionClusterTable.instance().saveEditData();
    }

    if (this.buyerTable !== null) {
      this.buyerTable.instance().closeEditCell();
      this.buyerTable.instance().saveEditData();
    }

    if (this.supplierTable !== null) {
      this.supplierTable.instance().closeEditCell();
      this.supplierTable.instance().saveEditData();
    }


    // make sure the data is cleared before saving it to the server
    if (!this.isPlatformUser) {
      this.model.platformRoleId = null;
    }
    if (!this.isAuctionClusterUser) {
      this.model.auctionClusterRoles = [];
    } else {
      this.model.auctionClusterRoles = this.model.auctionClusterRoles.filter(
        role => role.auctionClusterId && role.auctionClusterRoleId && (role.auctionId == null || role.auctionId));
    }
    if (!this.isBuyerUser) {
      this.model.buyerRoles = [];
    } else {
      this.model.buyerRoles = this.model.buyerRoles.filter(role => role.buyerId && role.buyerRoleId);
    }
    if (!this.isSupplierUser) {
      this.model.supplierRoles = [];
    } else {
      this.model.supplierRoles = this.model.supplierRoles.filter(role => role.supplierId && role.supplierRoleId);
    }

    if (!this.check()) {
      return;
    }

    if (!this.model.platformRoleId && this.model.auctionClusterRoles.length === 0 && this.model.buyerRoles.length === 0 && this.model.supplierRoles.length === 0) { // tslint:disable-line:max-line-length
      this.errorService.show(this.errorService.translations.AT_LEAST_ONE_LEVEL_NEEDS_TO_BE_SELECTED);
      return;
    }

    this.model.auctionClusterRoles.forEach(item => {
      if (item.catalogFilterData == null) {
        item.catalogFilterData = '-';
      }
      if (item.clockFilterData == null) {
        item.clockFilterData = '-';
      }
      if (item.auctionFilterData == null) {
        item.auctionFilterData = '-';
      }
    });
    
    this.model.domain = document.location.protocol + '//' + document.location.host;

    if (this.isEditMode) {
      this.spinner.show();
      this.dataService.edit(this.model)
        .subscribe((res: any) => {
          // this.model = new User();
          //this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
          this.languagePreferenceService.reload();
        },
        async error => {
          if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'ALL_BUYER_LICENCES_USED') {
            var buyerInfo: Buyer = await firstValueFrom(this.buyerService.getBuyer(error.error[0].ProductPropertyId));
            if (buyerInfo != null) {
              // offer to increase licensecount
              this.licenseConfirmation.title = this.errorService.translations.ALL_BUYER_LICENCES_USED;
              this.licenseConfirmation.message = this.translate.instant('USER.BUYER_ADD_LICENCE', { currentCount: buyerInfo.userLicenceCount, newCount: buyerInfo.userLicenceCount + 1 })
              this.licenseConfirmation.close.subscribe(() => {
                this.incrementLicenceCount(buyerInfo);
                this.save();
              });
              this.licenseConfirmation.opened = true;
            }
            else {
              // can't determine buyer for some reason, show generic message
              this.errorMessage = this.errorService.translations.ALL_BUYER_LICENCES_USED;
            }
          } else {
            this.errorMessage = this.errorService.toString(error);
          }
          if (this.errorMessage != null) {
            this.errorService.show(this.errorMessage);
          }
          this.spinner.hide();
        });
    } else {
      this.spinner.show();
      this.model.password = "Password01";
      this.model.confirmPassword = "Password01"

      this.dataService.save(this.model)
        .subscribe((res: any) => {
          this.model = new User();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  addAuctionCluster() {
    this.model.auctionClusterRoles.push(new UserAuctionClusterItem());
  }


  filterAuctionClusterItem(item: UserAuctionClusterItem) {
    item.filteredAuctions = this.auctions.filter(f => f.auctionClusterId === item.auctionClusterId || f.auctionClusterId === null);
    item.auctionId = null
    this.filterAuctionClusterRole(item);
  }

  filterAuctionClusterRole(item: UserAuctionClusterItem) {
    item.filteredAuctionClusterRoles = this.auctionClusterRoles.filter(f => f.auctionClusterId === item.auctionClusterId
      && (!f.isAuctionClusterTypeRole && !item.auctionId !== null || f.isAuctionClusterTypeRole && item.auctionId === null
        || !f.isAuctionClusterTypeRole && item.auctionId === null));
    const role = item.filteredAuctionClusterRoles.find(r => r.auctionClusterRoleId === item.auctionClusterRoleId);

    if (!role) {
      if (item.filteredAuctionClusterRoles.length > 0) {
        item.auctionClusterRoleId = item.filteredAuctionClusterRoles[0].auctionClusterRoleId;
      } else {
        item.auctionClusterRoleId = null;
      }
    }
  }

  deleteAuctionCluster(index: number) {
    this.model.auctionClusterRoles.splice(index, 1);
  }

  addBuyer() {
    this.model.buyerRoles.push(new UserBuyerItem());
  }

  filterBuyerItem(item: UserBuyerItem, buyerRoles: any) {
    item.filteredBuyerRoles = buyerRoles.filter(f => f.buyerId === item.buyerId);
    if (!item.buyerRoleId || !item.filteredBuyerRoles.find(r => r.buyerRoleId === item.buyerRoleId)) {
      item.buyerRoleId = item.filteredBuyerRoles.length > 0 ? item.filteredBuyerRoles[0].buyerRoleId : null;
    }
  }

  deleteBuyer(index: number) {
    this.model.buyerRoles.splice(index, 1);
  }

  addSupplier() {
    this.model.supplierRoles.push(new UserSupplierItem());
  }

  filterSupplierItem(item: UserSupplierItem, supplierRoles: any) {
    item.filteredSupplierRoles = supplierRoles.filter(f => f.supplierId === item.supplierId);
    if (!item.supplierRoleId || !item.filteredSupplierRoles.find(r => r.supplierRoleId === item.supplierRoleId)) {
      item.supplierRoleId = item.filteredSupplierRoles.length > 0 ? item.filteredSupplierRoles[0].supplierRoleId : null;
    }
  }

  deleteSupplier(index: number) {
    this.model.supplierRoles.splice(index, 1);
  }

  private check(): boolean {

    // create user list without current user
    const userList = this.allItems.filter((u) => {
      return u.userId !== this.model.userId;
    });

    const userNameNotUniqueList = userList.filter((u) => {
      return u.email === this.model.email;
    });

    if (userNameNotUniqueList.length > 0) {
      this.errorService.show(this.errorService.translations.EMAIL_NOT_UNIQUE);
      return false;
    }

    // check roles
    if (this.isPlatformUser && !this.model.platformRoleId) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_PLATFORM_ROLE);
      return false;
    }

    if (this.isAuctionClusterUser && this.model.auctionClusterRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_AUCTION_ROLES);
      return false;
    }

    if (this.isBuyerUser && this.model.buyerRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_BUYER_ROLES);
      return false;
    }

    if (this.isSupplierUser && this.model.supplierRoles.length === 0) {
      this.errorService.show(this.errorService.translations.NO_ASSIGNED_SUPPLIER_ROLES);
      return false;
    }

    if (!this.checkIfAuctionNull(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkAuctionClusters(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkAuctionClusterRows(this.model.auctionClusterRoles)) {
      return false;
    }

    if (!this.checkBuyers(this.model.buyerRoles)) {
      return false;
    }

    if (!this.checkSuppliers(this.model.supplierRoles)) {
      return false;
    }

    return true;
  }

  checkBuyers(buyerRoles: Array<UserBuyerRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < buyerRoles.length - 1; i++) {
      if (buyerRoles[i].buyerId === buyerRoles[i + 1].buyerId) {
        this.errorService.show(this.errorService.translations.BUYER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  checkSuppliers(supplierRoles: Array<UserSupplierRole>): boolean {

    let hasDuplicate = false;
    for (let i = 0; i < supplierRoles.length - 1; i++) {
      if (supplierRoles[i].supplierId === supplierRoles[i + 1].supplierId) {
        this.errorService.show(this.errorService.translations.SUPPLIER_HAS_ONE_ROLE);
        hasDuplicate = true;
      }
    }
    return !hasDuplicate;
  }

  checkAuctionClusters(auctionClusters: Array<UserAuctionClusterRole>): boolean {

    let isValid = false;

    const testArray = auctionClusters.map((element, index, self) => {
      const i = auctionClusters.findIndex(f => f.auctionClusterId === element.auctionClusterId && f.auctionId === element.auctionId && f.auctionClusterRoleId === element.auctionClusterRoleId); // tslint:disable-line:max-line-length
      return index !== i ? element : null;
    }).filter(f => f !== null);

    if (testArray.length !== 0) {
      isValid = true;
      this.errorService.show(this.errorService.translations.TWO_IDENTICAL_ROWS);
    }

    return !isValid;
  }

  checkIfAuctionNull(auctionClusterRoles: Array<UserAuctionClusterRole>): boolean {

    let hasNull = false;

    for (let i = 0; i < auctionClusterRoles.length; i++) {
      if (auctionClusterRoles[i].auctionId === null) {
        const findElementId = auctionClusterRoles[i].auctionClusterId;
        let count = 0;
        auctionClusterRoles.forEach(item => {
          if (item.auctionClusterId === findElementId) {
            count++;
            if (count > 1) {
              this.errorService.show(this.errorService.translations.ALL_AUCTIONS_INCLUDES_EVERY_ROLE);
              hasNull = true;
            }
          }
        });
      }
    }
    return !hasNull;
  }

  checkAuctionClusterRows(auctionClusterRoles: Array<UserAuctionClusterRole>): boolean {
    let rowsValid = false;
    auctionClusterRoles.forEach(row => {
      if (!row.auctionClusterId || !row.auctionClusterRoleId || (!row.auctionId && row.auctionId !== null)) {
        this.errorService.show(this.errorService.translations.CANNOT_SAVE_ROW_WITH_EMPTY_FIELDS);
        rowsValid = true;
      }
    });
    return !rowsValid;
  }

  public onCancel() {
    this.isOpened = false;
    this.errorMessage = null;
  }

  private async incrementLicenceCount(buyerInfo: Buyer) {
    try {
      this.spinner.show();
      buyerInfo.userLicenceCount++;
      buyerInfo = await firstValueFrom(this.buyerService.edit(buyerInfo));
    }
    catch (ex) {
      this.errorService.show(this.errorService.translations.GENERIC_ERROR);
      throw ex;
    }
    finally {
      this.spinner.hide();
    }
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].setAttribute('disabled', 'disabled');
        document.getElementsByName('btnUserSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].removeAttribute('disabled');
        document.getElementsByName('btnUserSubmit')[0].removeAttribute('disabled');
      }

      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].removeAttribute('disabled');
        document.getElementsByName('btnUserSubmit')[0].removeAttribute('disabled');
      } else {
        document.getElementsByName('btnUserSubmitAccountInfo')[0].setAttribute('disabled', 'disabled');
        document.getElementsByName('btnUserSubmit')[0].setAttribute('disabled', 'disabled');
      }
    }
  }

  onInitNewRow = (e: any) => {
    if (this.model.buyerRoles.length > 0) {
      window.setTimeout(function () { e.component.cancelEditData(); }, 0);
    }
  }

  onEditorPreparing = (e: any) => {
    if (
      e.dataField === 'buyerRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          const observables = [];
          observables.push(this.buyerRoleService
            .getBuyerRolesForBuyerPlatform(e.row.data.buyerId));
          return new Promise((resolve, reject) => {

            forkJoin(observables).subscribe(result => {
              let roles = result[0];
              resolve(roles);
            }, error => {
              reject(error);
            });
            
          });
        }
      };
    }

    if (
      e.dataField === 'supplierRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          const observables = [];
          observables.push(this.supplierRoleService
            .getSupplierRolesForSupplierPlatform(e.row.data.supplierId));
          return new Promise((resolve, reject) => {

            forkJoin(observables).subscribe(result => {
              let roles = result[0];
              resolve(roles);
            }, error => {
              reject(error);
            });
            
          });
        }
      };
    }

    if (
      e.dataField === 'auctionId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.auctions.filter(f => f.auctionClusterId === e.row.data.auctionClusterId || f.auctionClusterId === null));
              });
            
          });
        }
      };
    }

    if (
      e.dataField === 'auctionClusterRoleId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                resolve(this.auctionClusterRoles
                  .filter(f => f.auctionClusterId === e.row.data.auctionClusterId &&
                    (!f.isAuctionClusterTypeRole && !e.row.data.auctionId !== null ||
                      f.isAuctionClusterTypeRole && e.row.data.auctionId === null ||
                      !f.isAuctionClusterTypeRole && e.row.data.auctionId === null)));
              });
          });
        }
      };
    }
  }

  initSupplier = (e: any) => {
    this.supplierTable = e.component;
  }

  initBuyer = (e: any) => {
    this.buyerTable = e.component;
  }

  initAuctionCluster = (e: any) => {
    this.auctionClusterTable = e.component;
  }
}
