import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import notify from 'devextreme/ui/notify';
import { forkJoin, Subscription } from 'rxjs';
import DataGrid from 'devextreme/ui/data_grid';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

// general services
import { LanguageService } from '../../shared/services/language.service';
import { MailService } from '../../shared/services/mail.service';

// components
import { ItemListComponent } from '../../shared/components/item-list/item-list.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { LotteryService } from '../shared/services/lottery.service';
import { CustomAdminService } from '../shared/services/custom-admin.service';
import { ProductService } from '../shared/services/product.service';
import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';

// models
import { Catalog } from '../shared/models/catalog';
import { Lottery } from '../shared/models/lottery';
import { LotteryItem } from '../shared/models/lottery-item';
import { CustomAdminSettings } from '../shared/models/custom-admin-settings';

// enums
import { NotificationTypeEnum } from '../../shared/models/notificationTypeEnum';

export class MasterDataDropdown {
  rowId: number;
  value: string;
}

@Component({
  selector: 'lottery-component',
  templateUrl: 'lottery.component.html',
  styleUrls: ['./lottery.component.scss']
})
export class LotteryComponent extends ItemListComponent<LotteryItem> implements OnDestroy {

  catalogs: Array<Catalog> = [];
  customAdminSettings: CustomAdminSettings;
  catalogId: number;
  lastXSupplierCount: number = 10;
  lang: string;
  isLottery = false;
  confirmationErrorMessage: string;

  salesDate: Date;
  openingTime: Date;
  sortingTime: Date;
  salesTime: Date;
  remark: string;
  allMode: string;
  checkBoxesMode: string;
  selectedRows: Array<number> = [];

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private catalogService: CatalogService,
    private lotteryService: LotteryService,
    private translateService: TranslateService,
    private customAdminService: CustomAdminService,
    private mailService: MailService,
    private productService: ProductService,
    private notificationService: AuctionClusterNotificationService,
  ) {
    super(injector, LotteryItem);
    this.title.set('AUCTION.LOTTERY');
    this.lang = translateService.currentLang;

    var dateTimeNow = new Date();

    this.salesDate = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.openingTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.sortingTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.salesTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.remark = '';

    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getData() {
    this.spinner.show();

    forkJoin(
      this.catalogService.getAllSupplyAndForecastCatalogs(this.id),
      this.customAdminService.getSettings()
    ).subscribe(result => {
      this.catalogs = result[0];
      var catalog = this.catalogs.find(c => c.isForecast);
      if (catalog) {
        this.catalogId = catalog.catalogId;
      }
      else if (this.catalogs.length > 0) {
        this.catalogId = this.catalogs[0].catalogId;
      }
      this.customAdminSettings = result[1];
      this.spinner.hide();
    }, error => {
      this.errorService.show(error);
      this.spinner.hide();
    });
  }

  show() {
    this.spinner.show();

    forkJoin(
      this.lotteryService.getExistingLottery(this.id, this.catalogId, this.lastXSupplierCount, this.salesDate)
    ).subscribe(result => {
      this.parseResult(result);
    },
      error => {
        this.errorService.show(error);
      this.spinner.hide();
    });
  }

  parseResult(result) {
    this.salesDate = new Date(result[0].salesDate);
    if (result[0].openingTime) {
      var d = new Date(result[0].openingTime);
      this.openingTime = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()));
    }
    if (result[0].sortingTime) {
      var d = new Date(result[0].sortingTime);
      this.sortingTime = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()));
    }
    if (result[0].salesTime) {
      var d = new Date(result[0].salesTime);
      this.salesTime = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()));
    }
    this.remark = result[0].remark;

    result[0].lotteryList.forEach((lottery) => {
      lottery.orderNumber = lottery.automaticPosition;
      lottery.arrivalTimeString = lottery.arrivalTime
        ? moment.utc(lottery.arrivalTime).locale(this.lang).local().format('LT')
        : '';
      lottery.lastLotteryDateString = lottery.lastLotteryDate
        ? moment.utc(lottery.lastLotteryDate).locale(this.lang).local().format('L')
        : '';
      lottery.lastFirstPlaceDateString = lottery.lastFirstPlaceDate
        ? moment.utc(lottery.lastFirstPlaceDate).locale(this.lang).local().format('L')
        : '';
    });
    result[0].lotteryList.sort((a, b) => {
      return a.orderNumber - b.orderNumber;
    });
    this.items = result[0].lotteryList;
    this.items.map(_ => _.selected = false);
    this.spinner.hide();
    this.isLottery = result[0].isLottery;
  }

  showSuppliers() {
    this.spinner.show();

    forkJoin(
      this.lotteryService.getLottery(this.id, this.catalogId, this.lastXSupplierCount, false, this.salesDate)
    ).subscribe(result => {
      this.parseResult(result);
    },
      error => {
        this.errorService.show(error);
      this.spinner.hide();
    });
  }

  executeLottery() {
    this.spinner.show();

    forkJoin(
      this.lotteryService.getLottery(this.id, this.catalogId, this.lastXSupplierCount, true, this.salesDate)
    ).subscribe(result => {
      this.parseResult(result);
    },
      error => {
        this.errorService.show(error);
      this.spinner.hide();
    });
  }

  saveLottery() {
    if (this.items) {
      this.spinner.show();

      this.items.forEach((item) => {
        item.automaticPosition = item.orderNumber;
      });

      var lottery = new Lottery();
      lottery.salesDate = new Date(Date.UTC(this.salesDate.getFullYear(), this.salesDate.getMonth(), this.salesDate.getDate()));
      lottery.openingTime = this.openingTime;
      lottery.sortingTime = this.sortingTime;
      lottery.salesTime = this.salesTime;
      lottery.remark = this.remark;
      lottery.lotteryList = this.items;

      this.lotteryService.saveLottery(this.id, this.catalogId, this.isLottery, lottery)
        .subscribe((res: any) => {
          this.items = [];

          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  dateChanged(dateProperty: string, dateTime: Moment) {
    var date = null;
    if (dateProperty === 'salesDate') {
      date = this.salesDate;
    } else if (dateProperty === 'openingTime') {
      date = this.openingTime;
    } else if (dateProperty === 'sortingTime') {
      date = this.sortingTime;
    } else if (dateProperty === 'salesTime') {
      date = this.salesTime;
    }

    if (moment(date).isSame(dateTime)) {
      return;
    }

    if (dateProperty === 'salesDate') {
      this.salesDate = moment(dateTime).toDate();
    } else if (dateProperty === 'openingTime') {
      this.openingTime = moment(dateTime).toDate();
    } else if (dateProperty === 'sortingTime') {
      this.sortingTime = moment(dateTime).toDate();
    } else if (dateProperty === 'salesTime') {
      this.salesTime = moment(dateTime).toDate();
    }
  }

  catalogChanged() {
    var dateTimeNow = new Date();

    this.salesDate = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.openingTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.sortingTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.salesTime = new Date(dateTimeNow.getFullYear(), dateTimeNow.getMonth(), dateTimeNow.getDate());
    this.remark = '';
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getLocale(): string {
    if (navigator.languages) {
      return navigator.languages[0];
    }

    return navigator.language;
  }

  getCurrentTimeZoneOffset(): number {
    const currDate = new Date();
    return currDate.getTimezoneOffset();
  }

  moveUp() {
    var selectedRows = this.items.filter(_ => _.selected);

    selectedRows.forEach((row) => {
      const index = this.items.indexOf(row);
      if (index - 1 < 0) {
        return;
      }
      const moveProperty = this.items[index];
      const orderNumber = moveProperty.orderNumber;
      const bufferProperty = this.items[index - 1];
      moveProperty.orderNumber = bufferProperty.orderNumber;
      bufferProperty.orderNumber = orderNumber;
      this.items[index - 1] = moveProperty;
      this.items[index] = bufferProperty;
    });
  }

  moveDown() {
    var selectedRows = this.items.filter(_ => _.selected);
    selectedRows.sort((a, b) => (a.orderNumber < b.orderNumber) ? 1 : -1);

    selectedRows.forEach((row) => {
      const index = this.items.indexOf(row);
      if (index + 1 >= this.items.length) {
        return;
      }
      const moveProperty = this.items[index];
      const orderNumber = moveProperty.orderNumber;
      const bufferProperty = this.items[index + 1];
      moveProperty.orderNumber = bufferProperty.orderNumber;
      bufferProperty.orderNumber = orderNumber;
      this.items[index + 1] = moveProperty;
      this.items[index] = bufferProperty;
    });
  }

  createDataSchema() {
  }

  sendNotification(event: Event) {
    let catalog = this.catalogs.filter(_ => _.catalogId)[0];

    let type = NotificationTypeEnum.PublishingSupplyCatalog;

    if (catalog.isForecast) {
      type = NotificationTypeEnum.PublishingForecastCatalog;
    } 
    
    const dateFormatted = moment(this.salesDate).local().format('YYYY-MM-DD');

    this.spinner.show();
    this.notificationService.sendNotifications(catalog.auctionId, type, catalog.catalogId, null, dateFormatted, new Date().getTimezoneOffset())
    .subscribe((result) => {
      this.spinner.hide();
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });
  }
}
